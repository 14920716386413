import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder
} from "@angular/forms";
import { ActivitySelectPage } from '../activity-select/activity-select';
import { MyUtil } from "../../../libs/MyUtil";
import { CourseQuestionsPage } from "../course-questions/course-questions";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CancelBookingModal } from "src/app/components/cancel-booking-modal/cancel-booking-modal";
import { FileTransfer } from "@awesome-cordova-plugins/file-transfer/ngx";
import { NavParams } from "@ionic/angular";
import { ActivityService } from "src/app/providers/activity-service";
import { Appapi } from "src/app/providers/appapi";
import { CoursesService } from "src/app/providers/courses-service";
import { DesktopChecker } from "src/app/providers/desktopChecker";
import { FileService } from "src/app/providers/file-service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { TermsModalComponent } from "src/app/components/terms-modal/terms-modal.component";

declare var google;

@Component({
  selector: "page-course-details",
  templateUrl: "course-detail.html",
  styleUrls: ["course-detail.scss"],
})
export class CourseDetailPage implements OnInit, OnDestroy {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  routeData: any = {};
  tabIndex = 0;

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public router: Router,
    public navParams: NavParams,
    public formBuilder: UntypedFormBuilder,
    public appapi: Appapi,
    private route: ActivatedRoute,
    public sanitiser: DomSanitizer,
    public courseService: CoursesService,
    public desktopChecker: DesktopChecker,
    public activityService: ActivityService,
    public fileService: FileService,
    public transfer: FileTransfer,
    public coursesService: CoursesService,
  ) { }

  ngOnInit() {

    this.pageData.loaded = false;

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
    });

    this.pageData.homeOrg = MyUtil.getRootOrganization();
    this.pageData.activitiesList = [];

    this.coursesService.getCourseDetails(this.routeData.courseID).then((details) => {
      this.coursesService.checkUserIsBooked(this.routeData.courseID).then(bookingStatus => {
        this.pageData = details.data;
        console.log('this.pageData', this.pageData)

        //Booking status
        this.pageData.isBooked = false;
        this.pageData.onWaitingList = false;
        this.pageData.hybridOnline = false;
        if(bookingStatus && bookingStatus.course_id == this.routeData.courseID) {
          this.pageData.isBooked = !!bookingStatus.is_booked;
          this.pageData.onWaitingList = !!bookingStatus.on_waiting_list;
          this.pageData.hybridOnline = !!bookingStatus.hybrid_online;
        }

        let startDisplay = MyUtil.formatUnixTimeStampDate(this.pageData.activityDateRange.start_date);
        let endDisplay = MyUtil.formatUnixTimeStampDate(this.pageData.activityDateRange.end_date);
        let startTime = MyUtil.formatHISTimeFormatToAMPM(this.pageData?.start_time);
        let endTime = MyUtil.formatHISTimeFormatToAMPM(this.pageData?.end_time);
        this.pageData.period = (startDisplay === endDisplay ? startDisplay + (startTime === "00:00" ? '' : ' ' + startTime) : 'from ' + startDisplay + (startTime === "00:00" ? '' : ' ' + startTime) + ' to ' + endDisplay + (endTime === "00:00" ? '' : ' ' + endTime));

        if (this.pageData.price !== null && this.pageData.price > 0) {
          let rootOrg = MyUtil.getRootOrganization(this.pageData.oid);
          if (rootOrg?.currency_symbol !== '') {
            this.pageData.price = rootOrg.currency_symbol + this.pageData.price;
          } else {
            this.pageData.price = this.pageData.price;
          }
        }

        //Capacity
        this.pageData.hasCapacity = (this.pageData.capacity == -1 || this.pageData.capacity > this.pageData.bookings);
        this.pageData.hasOnlineCapacity = (this.pageData.online_capacity == -1 || this.pageData.online_capacity > this.pageData.online_bookings);

        if (this.pageData.isBooked) {
          this.tabIndex = 0;
        } else {
          this.tabIndex = 1;
        }

        this.pageData.helperToggle = true;
        this.pageData.start_atHumanized = MyUtil.formatUnixTimeStampDate(this.pageData.start_at);
        this.pageData.end_atHumanized = MyUtil.formatUnixTimeStampDate(this.pageData.end_at);
        this.pageData.startBound = 0;
        this.pageData.endBound = 0;

        //Booking timeframe
        //this.pageData.bookingClosed = this.pageData.activityDateRange.start_date <= Math.floor(MyUtil.getUnixTimeStamp() / 86400) * 86400;
        let currentTimestamp = MyUtil.getUnixTimeStamp();
        this.pageData.bookingNotOpenYet = currentTimestamp < this.pageData.booking_opens_ts;
        this.pageData.bookingClosed = currentTimestamp > this.pageData.booking_closes_ts;

        // get activities asociated with course
        this.pageData.activitiesList = [];

        this.pageData.bookingClashMessage = '';
        this.pageData.clashingActivities = [];
        this.courseService.getActivitiesInCourse(this.pageData.id, this.pageData.isBooked).then((res) => {
          Object.keys(res).forEach((prop) => {
            let dynamicShift: any = res[prop];
            this.pageData.activitiesList.push(dynamicShift);
          });

          this.courseService.listCourseBookingClashes(this.pageData.id, (this.pageData.isBooked || this.pageData.onWaitingList)).then((clashingActivities) => {
            this.pageData.clashingActivities = clashingActivities;
            if (Object.keys(this.pageData.clashingActivities).length > 0) {
              if (this.pageData.isBooked) {
                this.pageData.bookingClashMessage = 'WARNING - One or more booked activities conflict with another booking';
              } else if (this.pageData.onWaitingList) {
                this.pageData.bookingClashMessage = 'WARNING - One or more waiting list activities conflict with another booking';
              } else {
                this.pageData.bookingClashMessage = 'WARNING - One or more course activities potentially conflict with an existing booking';
              }
            };
          });


          if (this.pageData.isBooked) {
            this.statusCalcAndRemove();
          }

          this.setUpperAndLowerTimeBounds();

          this.pageData.loaded = true;
        })

      }).catch((err) => {
        console.log(err);
      });
    })
      //map skill id to skill and supplant variable
      let skillsArray = MyUtil.lodash
        .chain(this.pageData.skills)
        .map((id) => {
          return MyUtil.getSkill(id).name;
        })
        .filter((item) => {
          return (item != undefined);
        })
        .value();

      this.pageData.skills = MyUtil.lodash
        .chain(skillsArray)
        .value()
        .join(",");

      // force to do the profile first
      if (!MyUtil.validateProfile()) {
        MyUtil.presentToast('Please complete your profile first', { cssClass: 'inkpath-toast' });
        this.router.navigate(['/UserSettingsPage', '{}']);
      }
   
  }

  /** 
  * Status calc and remove.
  */
  public statusCalcAndRemove() {

    this.courseService.getActivityStatusesByCourseId(this.pageData.id).then((res) => {
      let sumConfirm: number = 0;
      let origLength: number = 0;
      res.forEach((status, index) => {
        let refObject = this.pageData.activitiesList.find(item => item.id === status.activity_id);
        if (refObject) {
          if (status.is_booked == 1) {
            refObject.shouldDisplay = true;
            refObject.activityStatus = "";
            // set status in activity object
            if (status.status == null) {
              refObject.activityStatus = 0;
            } else {
              refObject.activityStatus = status.status;
            }
            if (refObject.is_required == 1) {
              origLength += 1;
              // for calculating percentage
              if (status.status == 2) {
                sumConfirm += 1;
              }
            }
          } else {
            if (refObject.is_required == 0)
              refObject.shouldDisplay = false;
            else
              refObject.shouldDisplay = true;
          }
        }

      });
      sumConfirm > 0 ? this.pageData.courseProgressPercentage = (100 * sumConfirm) / origLength : this.pageData.courseProgressPercentage = 0;
      this.pageData.courseProgressPercentage = Math.round(this.pageData.courseProgressPercentage);
    }).catch((err) => {
      console.log(err);
    });
    //sort activities by start date
    this.pageData.activitiesList = this.pageData.activitiesList.sort((a, b) => (a.start_at > b.start_at ? 1 : -1))
  }

  /** 
  * Show booking cancel modal.
  */
  public async showBookingCancelModal() {
    if (!MyUtil.isNetworkConnected()) {
      let toast = MyUtil.presentToast(
        "Please connect to the internet and try again.", { cssClass: 'inkpath-toast' }
      );
      return;
    }
    let title = "Are you sure you want to cancel your booking?";
    let buttonText = "Cancel booking";
    if (this.pageData.onWaitingList) {
      title = "Are you sure you want to cancel your place on the waiting list?";
      buttonText = "Cancel place";
    }
    let cancelBookingModal = MyUtil.createModal(CancelBookingModal, {
      title: title,
      message: "This course will still be shown in your saved courses. Please select the reason for your cancellation.",
      buttons: [
        {
          text: "Close",
        },
        {
          text: buttonText,
        },
      ],
      labels: MyUtil.CANCEL_BOOKINGS_LABELS,
    });
    (await cancelBookingModal).onDidDismiss().then((labelKey: any) => {
      if (labelKey.data) {
        this.cancelCourseBooking(labelKey.data);
      }
    });
    (await cancelBookingModal).present();
  }

   /** 
  * Cancel course booking.
  */
   private cancelCourseBooking(labelKey: number) {
    let loading = MyUtil.presentLoading();
    this.courseService.cancelBooking(this.pageData.id, labelKey).then(async (res) => {
      if (res) {
        if (this.pageData.isBooked) {
          this.showBookingAlert("Course booking cancelled");
        } else if (this.pageData.onWaitingList) {
          this.showBookingAlert("Place cancelled");
        }

        this.refreshPage(false, false);
      } else {
        this.showBookingAlert("Error cancelling booking");
      }
      (await loading).dismiss();
    });
  }

   /** 
  * Refresh page.
  */
   public refreshPage(isBooked: boolean, onWaitingList: boolean) {
    this.coursesService.getCourseDetails(this.pageData.id).then((result) => {
      this.router.navigate(['/CourseDetailPage', JSON.stringify({ courseID: this.pageData.id, isBooked: isBooked, onWaitingList: onWaitingList })]);
    });
  }

  /** 
  * Open activity.
  * @param id (number)
  */
  public openActivity(id: number) {
    //console.log('HERE!!! ', this.pageData.activitiesList.find(i => i.id === id))
    this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: id, activityObject: this.pageData.activitiesList.find(i => i.id === id), isCourseActivity: true })]);
  }

  // for mark as complete widget. will need to be re-added so keep here
  private completeActivity(id) {
    // go to complete page, no pop up
    this.pageData.noBookingWarningPopup = true;

    let params = {
      id: id,
      backToGoal: false,
      attendanceCode: null,
    };
    this.router.navigate(['/ActivityCompletePage', JSON.stringify(params)]);
  }

  /** 
  * Book course.
  */
  public async bookCourse() {
    if (!this.pageData.bookingClosed && !this.pageData.bookingNotOpenYet) {
      if(this.pageData.terms_text) {
        //Display popup asking user to agree to Ts&Cs
        let modal = MyUtil.createModal(TermsModalComponent,
          {
            message: this.sanitiser.bypassSecurityTrustHtml(this.pageData.terms_text),
          });
        (await modal).onDidDismiss().then((data: any) => { 
          if (data.data !== undefined) {
            this.openCourseQuestionsModal();
          } 
        });
        (await modal).present();
      } else {
        //No Ts&Cs
        this.openCourseQuestionsModal();
      }
      return;
    } else if (this.pageData.bookingNotOpenYet) {
      this.showBookingAlert("Booking opens at " + this.pageData.booking_opens_at);
    } else {
      this.showBookingAlert("Booking closed");
    }
  }


  /** 
  * Book hybrid course/convert hybrid course booking type
  */
  public async bookHybridCourse(hybridOnline: boolean, isConvert: boolean = false) {
    if (!this.pageData.bookingClosed && !this.pageData.bookingNotOpenYet) {
      if(isConvert) {
        let convertMessage = '';
        //Display confirmation prompt
        if(hybridOnline) {
          let toOnlineStatus = this.pageData.hasOnlineCapacity ? 'booking' : (this.pageData.online_capacity > 0 ? 'waiting list' : 'application list');
          let inPersonStatus = this.pageData.onWaitingList ? (this.pageData.capacity > 0 ? 'waiting list' : 'application list') : 'booking';
          convertMessage = "<b>Convert from in-person " +  inPersonStatus + " to online " + toOnlineStatus + "?</b><br/><br/>This will release your in-person place and move you to the online " + toOnlineStatus + ".";
          if(this.pageData.hasCapacity && !this.pageData.hasOnlineCapacity) {
            convertMessage = convertMessage + " You will no longer have a confirmed place.";
          } else if(!this.pageData.hasCapacity) {
            convertMessage = convertMessage + " The in-person event is " + (this.pageData.capacity == 0 ? 'by application only' : 'full') + " so you may not be able to return to an in-person booking.";
          }
        } else {
          let onlineStatus = this.pageData.onWaitingList ? (this.pageData.online_capacity > 0 ? 'waiting list' : 'application list') : 'booking';
          let toInPersonStatus = this.pageData.hasCapacity ? 'booking' : (this.pageData.capacity > 0 ? 'waiting list' : 'application list');
          convertMessage = "<b>Convert from online " +  onlineStatus + " to in-person " + toInPersonStatus + "?</b><br/><br/>This will release your online place and move you to the in-person " + toInPersonStatus + ".";
          if(this.pageData.hasOnlineCapacity && !this.pageData.hasCapacity) {
            convertMessage = convertMessage + " You will no longer have a confirmed place.";
          } else if(!this.pageData.hasOnlineCapacity) {
            convertMessage = convertMessage + " The online event is " + (this.pageData.online_capacity == 0 ? 'by application only' : 'full') + " so you may not be able to return to an online booking.";
          }
        }

        if(convertMessage != "") {
          MyUtil.presentAlert({
            message: convertMessage,
            buttons: [
              {
                text: 'Cancel',
                handler: () => {
                  return;
                }
              },
              {
                text: 'Confirm',
                handler: () => {
                  this.openCourseQuestionsModal(hybridOnline, isConvert);
                }
              }
            ],
          });
        }
      } else {
        //New booking
        if(this.pageData.terms_text) {
          //Display popup asking user to agree to Ts&Cs
          let modal = MyUtil.createModal(TermsModalComponent,
            {
              message: this.sanitiser.bypassSecurityTrustHtml(this.pageData.terms_text),
            });
          (await modal).onDidDismiss().then((data: any) => { 
            if (data.data !== undefined) {
              this.openCourseQuestionsModal(hybridOnline, isConvert);
            } 
          });
          (await modal).present();
        } else {
          //No Ts&Cs
          this.openCourseQuestionsModal(hybridOnline, isConvert);
        }
      }
    } else if (this.pageData.bookingNotOpenYet) {
      this.showBookingAlert("Booking opens at " + this.pageData.booking_opens_at);
    } else {
      this.showBookingAlert("Booking closed");
    }
  }


  /** 
  * Open course questions modal.
  */
  public openCourseQuestionsModal(hybridOnline: boolean = false, convert:boolean = false) {
    this.courseService.getCoursesQuestions(this.pageData.id).then(async (res) => {
      let questionsData = res;
      if (typeof res === 'string') {
        this.showBookingAlert(res);
        return;
      }

      if (questionsData.length > 0) {
        //If hybridOnline, remove any activity booking questions that don't make sense in an online context (parking etc)
        let filteredQuestionsData = [];
        if(hybridOnline) {
          filteredQuestionsData = questionsData.filter((question) => {
            return question.label != 'require_accommodation' 
              && question.label != 'require_parking' 
              && question.label != 'require_dietary' 
              && question.label != 'require_wheelchair' 
              && question.label != 'require_accessible_parking'
          })
        } else {
          filteredQuestionsData = questionsData;
        }

        // create questions modal
        let courseQuestionsModal = MyUtil.createModal(CourseQuestionsPage, { questions: !!filteredQuestionsData ? filteredQuestionsData : null });

        // assign dismiss logic to modal
        (await courseQuestionsModal).onDidDismiss().then(data => {
          if (data.data != null) {
            if(convert) {
              this.convertBooking(data.data, hybridOnline);
            } else {
              this.openActivitySelectModal(data.data, hybridOnline);
            }
          } else {
            this.openDisclaimerModal();
          }
        });
        (await courseQuestionsModal).present();

      } else {
        if(convert) {
          this.convertBooking({}, hybridOnline);
        } else {
          this.openActivitySelectModal({}, hybridOnline);
        }
      }
    });
  }

  /** 
  * Open activity select modal.
  */
 public async openActivitySelectModal(questionData: any, hybridOnline: boolean = false, convert:boolean = false) {

    let activitySelectModal = MyUtil.createModal(ActivitySelectPage, { global: false, activities: this.pageData.activitiesList, clashingActivities: this.pageData.clashingActivities });
    (await activitySelectModal).onDidDismiss().then((data: any) => {
      if (data.data != null) {
        if (data.data && data.data.length > 0) {
          let postObj = {
            activities: data.data,
            details: questionData.details,
            hybridOnline: hybridOnline, 
          }

          let loading = MyUtil.presentLoading();
          this.courseService.bookPlaceOnCourse(this.pageData.id, postObj).then(async (res) => {
            if (res.is_booked) {
              this.showBookingAlert("Booking confirmed");
            } else if (res.on_waiting_list) {
              this.showBookingAlert("You have been added to the course waiting list");
            }

            (await loading).dismiss();
            this.refreshPage(res.is_booked, res.on_waiting_list);

          }).catch(async (err) => {
            this.showBookingAlert("Error booking course");
            (await loading).dismiss();
          });
        }
      } else {
        this.openDisclaimerModal();
      }
    });

    (await activitySelectModal).present();

  }


  convertBooking(questionData: any, hybridOnline: boolean) {
    let postObj = {
      details: questionData.details,
      hybridOnline: hybridOnline, 
    }

    let loading = MyUtil.presentLoading();
    this.courseService.convertHybridCourseBooking(this.pageData.id, postObj).then(async (res) => {
      if (res.is_booked) {
        this.showBookingAlert("Your course booking has been converted to " + (hybridOnline ? 'online' : 'in-person'));
      } else if (res.on_waiting_list) {
        this.showBookingAlert("You have been added to the " + (hybridOnline ? 'online' : 'in-person') + " course waiting list");
      }

      (await loading).dismiss();
      this.refreshPage(res.is_booked, res.on_waiting_list);

    }).catch(async (err) => {
      this.showBookingAlert("Error booking course");
      (await loading).dismiss();
    });
  }


  /** 
  * Open disclaimer modal.
  */
  public openDisclaimerModal() {
    this.showBookingAlert("Warning: Booking process is not complete. Please select your sessions then press the Save button.");
  }

  /** 
  * Show booking alert.
  */
  public showBookingAlert(response) {
    MyUtil.presentAlert({
      message: response,
      buttons: [
        {
          text: 'OK',
          role: 'OK',
          handler: () => {
            return;

          }
        }
      ]
    });
  }

  /** 
  * Get displayable activities.
  */
  getDisplayableActivities(): any[] {
    if (this.pageData.activitiesList)
      return this.pageData.activitiesList.filter(x => x.shouldDisplay == true);
    else return [];
  }

  /** 
  * Set upper and lower timeBounds.
  */
  setUpperAndLowerTimeBounds() {
    this.pageData.activitiesList.forEach(element => {
      if (element.start_at < this.pageData.startBound || this.pageData.startBound == 0) {
        this.pageData.startBound = element.start_at;
      }

      if (element.end_at > this.pageData.endBound || this.pageData.endBound == 0) {
        this.pageData.endBound = element.end_at;
      }

    });
    this.pageData.start_atHumanized = MyUtil.formatUnixTimeStampDate(this.pageData.startBound);
    this.pageData.end_atHumanized = MyUtil.formatUnixTimeStampDate(this.pageData.endBound);
  }


  // for ngStyle on activity status labels
  public getStatusStyle(status: string) {
    switch (status) {
      case "Not Started": {
        return {
          "color": "red",
          "background": "#4c181d"
        }
      }
      case "Active": {
        return {
          "color": "yellow",
          "background": "#9e770a"
        }
      }
      case "Done": {
        return {
          "color": "green",
          "background": "#0f4808"
        }
      }
    }
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
