import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AboutPage } from 'src/app/pages/about/about';
import { ActivityBookingPage } from 'src/app/pages/activity-booking/activity-booking';
import { ActivityCompletePage } from 'src/app/pages/activity-complete/activity-complete';
import { ActivityDetailPage } from 'src/app/pages/activity-detail/activity-detail';
import { ActivityEditPage } from 'src/app/pages/activity-edit/activity-edit';
import { ActivityListPage } from 'src/app/pages/activity-list/activity-list';
import { CareerPathSkillTileDetailsPage } from 'src/app/pages/career-path-skill-tile-details/career-path-skill-tile-details';
import { CareerPathPage } from 'src/app/pages/career-path/career-path';
import { ChooseAPathwayPage } from 'src/app/pages/choose-a-pathway/choose-a-pathway';
import { CourseDetailPage } from 'src/app/pages/course-detail/course-detail';
import { CoursesListPage } from 'src/app/pages/courses-list/courses-list';
import { EmailSettingsPage } from 'src/app/pages/email-settings/email-settings';
import { FundingListPage } from 'src/app/pages/funding-list/funding-list';
import { GoalDetailPage } from 'src/app/pages/goal-detail/goal-detail';
import { GoalEditPage } from 'src/app/pages/goal-edit/goal-edit';
import { GoalListPage } from 'src/app/pages/goal-list/goal-list';
import { LoginPage } from 'src/app/pages/login/login';
import { ManagedFilePage } from 'src/app/pages/managed-file/managed-file';
import { PasswordPage } from 'src/app/pages/password/password';
import { PathwayManualCareerTypePage } from 'src/app/pages/pathway-manual-career-type/pathway-manual-career-type';
import { PathwayManualPage } from 'src/app/pages/pathway-manual/pathway-manual';
import { PathwaySuggestedPage } from 'src/app/pages/pathway-suggested/pathway-suggested';
import { QuestionnaireIntroPage } from 'src/app/pages/questionnaire-intro/questionnaire-intro';
import { QuestionnairePage } from 'src/app/pages/questionnaire/questionnaire';
import { RscLoginPage } from 'src/app/pages/rsc-login/rsc-login';
import { SkillDetailsPage } from 'src/app/pages/skill-details/skill-details';
import { SkillsAnalysisSummaryPage } from 'src/app/pages/skills-analysis-summary/skills-analysis-summary';
import { SkipQuestionnaire } from 'src/app/pages/skip-questionnaire/skip-questionnaire';
import { UserSettingsPage } from 'src/app/pages/user-settings/user-settings';
import { UserPage } from 'src/app/pages/user/user';
import { ActivitiesPage } from './pages/activities/activities';
import { DiagnosticListPage } from './pages/diagnostic-list/diagnostic-list';
import { DiagnosticQuestionsPage } from './pages/diagnostic-questions/diagnostic-questions';
import { DiagnosticSummaryListPage } from './pages/diagnostic-summary-list/diagnostic-summary-list';
import { DiagnosticSummaryPage } from './pages/diagnostic-summary/diagnostic-summary';
import { GoalsPage } from './pages/goals/goals';
import { OnboardingPage } from './pages/onboarding/onboarding';
import { QuestionnaireSelectPage } from './pages/questionnaire-select/questionnaire-select';
import { RecommendedComponent } from './pages/recommended/recommended.component';
import { RedirectPage } from './pages/redirect/redirect';
import { ReflectionDetailsPage } from './pages/reflection-details/reflection-details';
import { ReflectionPage } from './pages/reflection/reflection';
import { RscCookiesPage } from './pages/rsc-cookies/rsc-cookies';
import { RscDiagnosticSummaryComponent } from './pages/rsc-diagnostic-summary/rsc-diagnostic-summary.component';
import { RscReviewAnswersComponent } from './pages/rsc-review-answers/rsc-review-answers.component';
import { AuthGuardService } from './services/auth-guard.service';
import { GuestGuardService } from './services/guest-guard.service';
import { SetPasswordComponent } from './set-password/set-password.component';


const routes: Routes = [
  {
    path: 'LoginPage',
    component: LoginPage,
    canActivate: [GuestGuardService]
  },
  {
    path: 'LoginPage/:pageData',
    component: LoginPage,
    canActivate: [GuestGuardService]
  },
  {
    path: 'SetPasswordComponent/:pageData',
    component: SetPasswordComponent,
    canActivate: [GuestGuardService]
  },
  {
    path: 'rsc/auth',
    component: RscLoginPage,
    canActivate: [GuestGuardService]
  },
  {
    path: '',
    component: ActivitiesPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'redirect/:pageData',
    component: RedirectPage,
    //canActivate: [AuthGuardService]
  },
  {
    path: 'redirectGuest/:pageData',
    component: RedirectPage,
    canActivate: [GuestGuardService]
  },
  {
    path: 'ReflectionPage/:pageData',
    component: ReflectionPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'RecommendedComponent',
    component: RecommendedComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'ReflectionDetailsPage/:pageData',
    component: ReflectionDetailsPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'DiagnosticSummaryPage/:pageData',
    component: DiagnosticSummaryPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'RscDiagnosticSummaryComponent/:pageData',
    component: RscDiagnosticSummaryComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'RscReviewAnswers/:pageData',
    component: RscReviewAnswersComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'DiagnosticSummaryListPage/:pageData',
    component: DiagnosticSummaryListPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'DiagnosticQuestionsPage/:pageData',
    component: DiagnosticQuestionsPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'OnboardingPage/:pageData',
    component: OnboardingPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'DiagnosticListPage/:pageData',
    component: DiagnosticListPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'AboutPage',
    component: AboutPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'CareerPathSkillTileDetailsPage/:pageData',
    component: CareerPathSkillTileDetailsPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'CareerPathPage/:pageData',
    component: CareerPathPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'UserSettingsPage/:pageData',
    component: UserSettingsPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'ActivityListPage/:pageData',
    component: ActivityListPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'ActivityDetailPage/:pageData',
    component: ActivityDetailPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'ActivityEditPage/:pageData',
    component: ActivityEditPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'CourseDetailPage/:pageData',
    component: CourseDetailPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'ActivityCompletePage/:pageData',
    component: ActivityCompletePage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'ActivityBookingPage/:pageData',
    component: ActivityBookingPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'ManagedFilePage/:pageData',
    component: ManagedFilePage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'UserPage',
    component: UserPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'SkillDetailsPage/:pageData',
    component: SkillDetailsPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'PathwaySuggestedPage',
    component: PathwaySuggestedPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'PathwayManualPage',
    component: PathwayManualPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'CoursesListPage',
    component: CoursesListPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'GoalEditPage/:pageData',
    component: GoalEditPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'GoalsPage',
    component: GoalsPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'GoalDetailPage/:id',
    component: GoalDetailPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'GoalListPage/:profile',
    component: GoalListPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'SkillsAnalysisSummaryPage/:pageData',
    component: SkillsAnalysisSummaryPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'EmailSettingsPage',
    component: EmailSettingsPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'PathwayManualCareerTypePage/:pageData',
    component: PathwayManualCareerTypePage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'QuestionnaireIntroPage/:pageData',
    component: QuestionnaireIntroPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'ChooseAPathwayPage/:pageData',
    component: ChooseAPathwayPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'QuestionnaireSelectPage',
    component: QuestionnaireSelectPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'PasswordPage/:pageData',
    component: PasswordPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'FundingListPage/:pageData',
    component: FundingListPage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'SkipQuestionnaire',
    component: SkipQuestionnaire,
    canActivate: [AuthGuardService]
  },
  {
    path: 'QuestionnairePage/:data',
    component: QuestionnairePage,
    canActivate: [AuthGuardService]
  },
  {
    path: 'cookies',
    component: RscCookiesPage
  }
];

@NgModule({
  imports: [
    CommonModule, 
    BrowserModule,  
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { 
}
