import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
 
import { NavParams } from '@ionic/angular';
import { ActivityService } from 'src/app/providers/activity-service';
import { Appapi } from 'src/app/providers/appapi';
import { CoursesService } from 'src/app/providers/courses-service';
import { MyUtil } from '../../../libs/MyUtil';

import { Barcode, BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'qr-scanner',
  templateUrl: 'qr-scanner.html',
  styleUrls: ['qr-scanner.scss']
})
export class QrScannerComponent implements OnInit {
  pageData: any = {};

  isSupported = false;
  barcodes: Barcode[] = [];

  ngOnInit(): void {
    BarcodeScanner.isSupported().then((result) => {
      this.isSupported = result.supported;
    });
  }

  async scan(): Promise<void> {
 

    if (!MyUtil.isMobileAppMode()) {
      this.enterAttendanceCode()
      return;
    }else{
      // Scan
      const granted = await this.requestPermissions();
      if (!granted) {
        this.presentAlert();
        return;
      }
      const { barcodes } = await BarcodeScanner.scan();
      this.barcodes.push(...barcodes);
      console.log('barcodes', barcodes)

    }
    //  this.matchActivitiesOfAttendanceCode(result.content);
  }


  enterAttendanceCode() {

    let alert = MyUtil.presentAlert({
      title: 'Enter The Attendance Code',
      message: "Please enter the attendance code of the activity.",
      inputs: [
        {
          name: 'code',
          placeholder: 'Attendance Code'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Submit',
          handler: (data: any) => {
            MyUtil.debug(data);
            this.matchActivitiesOfAttendanceCode(data.code);
          }
        }
      ]
    });
  }

  async requestPermissions(): Promise<boolean> {
    const { camera } = await BarcodeScanner.requestPermissions();
    return camera === 'granted' || camera === 'limited';
  }

  async presentAlert(): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Permission denied',
      message: 'Please grant camera permission to use the barcode scanner.',
      buttons: ['OK'],
    });
    await alert.present();
  }


  constructor(public router: Router, public navParams: NavParams,
    private alertController: AlertController,
    public coursesService: CoursesService,
    public appapi: Appapi, public activityService: ActivityService) {

    // only show add to calendar in mobile app mode
    this.pageData.isMobileAppMode = MyUtil.isMobileAppMode();
  }



  private matchActivitiesOfAttendanceCode(code: string) {
    if (code) {
      let loading = MyUtil.presentLoading();
      let data = {
        attendanceCode: code
      };
      
      
      this.appapi.post('/find/activities-of-attendance-code', data).then(async (result) => {
        (await loading).dismiss();
        MyUtil.debug(result);

        if (result['#status'] === 'success') {
          if (result['#data'] && result['#data'].length > 0) {
            // make sure the activity is visible to the user
            let activities = MyUtil.lodash.chain(MyUtil.cache[MyUtil.DOC_ID.ACTIVITIES])
              .cloneDeep().merge(MyUtil.cache[MyUtil.DOC_ID.FUNDING_ACTIVITIES]).merge({ 'a': 1 }).value();

            let activity = null;
            for (var i = 0; i < result['#data'].length; i++) {
              activity = activities[result['#data'][i]];
              if (activity) {
                // find first matched activity
                break;
              }
            }

            // is this a course activity (which are not included in the main activities list)
            if (!activity && result['#courseActivity'] && Object.keys(result['#courseActivity']).length > 0) {

              activity = result['#courseActivity'];

              // Is course booked?
              let isCourseBooked = 0;
              let onCourseWaitingList = 0;
              let bookingDetails;
              await this.coursesService.checkUserIsBooked(activity.course_id).then((result) => {
                if(result) {
                  bookingDetails = result;
                  isCourseBooked = bookingDetails.is_booked;
                  onCourseWaitingList = bookingDetails.on_waiting_list;
                }
              });

              // Is activity booked?
              let isActivityBooked = 0;
              await this.activityService.listBookingStatus().then((result) => {
                if(result && result[activity.id]) {
                  isActivityBooked = result[activity.id].is_booked;
                } else {
                  isActivityBooked = 0;
                }
              });

              // Booking open or closed?
              let courseDetails;
              await this.coursesService.getCourseDetails(activity.course_id).then((result) => {
                courseDetails = result.data;
              });
              let currentTimestamp = MyUtil.getUnixTimeStamp();
              let bookingOpen = currentTimestamp > courseDetails.booking_opens_ts;
              let bookingClosed = currentTimestamp > courseDetails.booking_closes_ts;
              let bookingOpenAndNotClosed = bookingOpen && !bookingClosed;
              let isFull = (courseDetails.capacity != -1 && courseDetails.capacity <= courseDetails.bookings);

              // If user not booked on course at all and course is still bookable
              if(bookingOpenAndNotClosed && !isActivityBooked && !isCourseBooked && !onCourseWaitingList && !isFull) {
                // “Please book onto this course, then try registering your session completion again” 
                // → OK button sends user to course page
                MyUtil.presentAlert({
                  message: "You are not booked into this session.\n Please book onto this course, then try registering your session completion again.",
                  buttons: [
                    {
                      text: "Okay",
                      handler: () => {
                        this.router.navigate(['/CourseDetailPage', 
                        JSON.stringify({
                           CourseDetails: courseDetails, 
                           bookingDetails: null })]);
                      },
                    },
                  ],
                });
                return;
              } else if(!isCourseBooked || !isActivityBooked) {
                // Booking might be closed or they might be on waiting list, and user not booked on course or not on activity - the admin needs to sort this out!
                // “Please contact your administrator to amend your booking and register participation // [public contact address]” 
                // → OK button sends user to course page
                let profile: any = MyUtil.getProfile();
                let oid = profile.oid;
                MyUtil.presentAlert({
                  message: `You are not booked into this session.\n Please contact your administrator to amend your booking and register participation. ${MyUtil.getRootOrganization(oid).public_contact_email}`,
                  buttons: [
                    {
                      text: "Okay",
                      handler: () => {
                        this.router.navigate(['/CourseDetailPage', 
                        JSON.stringify({
                           CourseDetails: courseDetails, 
                           bookingDetails: bookingDetails })]);
                      },
                    },
                  ],
                });
                return;
              }
            }

            if (activity) {
              // add to user activity list and go to detail page
              this.activityService.addToUserActivity(activity.id, null, true).then(() => {
                if (result['#courseActivity'] && Object.keys(result['#courseActivity']).length > 0) {
                  // the course activity doesn't exist in local storage so will present a blank activity page
                  // so we'll redirect to the main activities page instead
                  this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: activity.id, 'isCourseActivity': true, 'activityObject': activity, 'attendanceCode': code })]);
                } else {
                  this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: activity.id, 'attendanceCode': code })]);
                }
              });
            } else {
              MyUtil.presentToast('Activity not found. Please make sure you have data synchronised from the server or try again later.', { cssClass: 'inkpath-toast' });
            }
          } else {
            MyUtil.presentToast(result['#message'], { cssClass: 'inkpath-toast' });
          }
        } else {
          MyUtil.presentToast(result['#message'], { cssClass: 'inkpath-toast' });
        }
        
      }).catch(async err => {
        (await loading).dismiss();
        MyUtil.error(err);
        this.showOfflineAlert();
      });
    }
  }

  private showOfflineAlert() {
    let alert = MyUtil.presentAlert({
      title: 'Failed To Connect To Server',
      message: "Please connect to the internet to scan an activity straight into your profile. Alternatively, you can search for the activity using the Find Activities button, add it to your activities, and then scan the QR code when you mark the activity as complete.",
      buttons: [
        {
          text: 'OK',
        }
      ]
    });
  }
}
