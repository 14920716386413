import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
 
import { MyUtil } from '../../../libs/MyUtil';
import { MyDb } from '../../../libs/MyDb';
import { NavController, NavParams } from '@ionic/angular';
import { ActivityService } from 'src/app/providers/activity-service';
import { Appapi } from 'src/app/providers/appapi';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'page-activity-complete',
  templateUrl: 'activity-complete.html',
  styleUrls: ['activity-complete.scss'],
})
export class ActivityCompletePage implements OnInit, OnDestroy {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  routeData: any = {};
  hours: number = 0;
  complete: boolean;
  rating: number = 0;
  recommend: number = null;
  feedback: string;
  attendanceCode: string;
  showAttendanceCode = false;
  reflection: string;
  hide_hours = false;
  showHoursError = true;

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public viewCtrl: NavController,
    private route: ActivatedRoute,
    private _location: Location,
    public router: Router, public navParams: NavParams,
    public appapi: Appapi, public activityService: ActivityService) { }

    ngOnInit(): void {

      this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
        this.routeData = JSON.parse(params.pageData)
      });
  
      // expand help for first time landing on the page
      if (MyUtil.context.helpStatus[MyUtil.HELP_ID.ACTIVITY_COMPLETE]) {
        this.pageData.helperToggle = false;
      } else {
        this.pageData.helperToggle = true;
        this.appapi.setAppHelpStatus(MyUtil.HELP_ID.ACTIVITY_COMPLETE, true);
      }
  
      this.attendanceCode = this.routeData.attendanceCode;
  
      if (this.attendanceCode !== null) {
        this.pageData.verifiedAttendanceCode = true;
      }
  
      let id = this.routeData.id;
      let isCourseActivity = this.routeData.isCourseActivity;
      this.pageData.activity = MyUtil.getActivity(id) || this.routeData.activityObject;

      // if no activity, check for course activity
      if (!this.pageData.activity) {
        if (isCourseActivity === true) {
          this.pageData.activity = this.routeData.activityDetails;
        }
      }
  
      this.pageData.userActivityDoc = null;
      this.pageData.isMobileAppMode = MyUtil.isMobileAppMode();

      //Set up hours slider
      this.appapi.ignoreLoggedHours(this.pageData.activity.oid).then(res => {
        if(res == 'true') {
          this.hide_hours = true;
          this.showHoursError = false;
        }

        if(!this.hide_hours) {
          this.pageData.min = 0;
          this.pageData.max = 8 * 2;  // set as 8 hours but with half hour intervals
      
          if (this.pageData.activity.start_time && this.pageData.activity.end_time) {
            let startDate = MyUtil.createDate(this.pageData.activity.start_at * 1000, this.pageData.activity.start_time).getTime();
            let endDate = MyUtil.createDate(this.pageData.activity.end_at * 1000, this.pageData.activity.end_time).getTime();
            // Set the duration hours to the length of time the activity took, rounded to the nearest 30 minutes, up to the max
            let duration = endDate - startDate;
            let durationInHalfHours = Math.round(duration / 1800000);
            this.hours = (durationInHalfHours <= this.pageData.max) ? durationInHalfHours : this.pageData.max;
          } else {
            if (this.pageData.activity.start_at !== this.pageData.activity.end_at) {
              // If there's no start/end time and the activity spans multiple days, set the duration to the max
              this.hours = this.pageData.max;
            }
          }

          // Self study default hours
          if(this.pageData.activity.attendance_type === 3) {
            this.hours = 0;
          }

          this.showHoursError = this.hours === 0;
        }
      });
  
      MyUtil.firebaseSetScreenName('activity-complete');
      MyUtil.firebaseLogEvent('view_did_enter', { name: 'activity-complete', data: this.navParams.data });
  
      this.activityService.queryUserActivity(this.pageData.activity.id).then((doc) => {
        this.pageData.userActivityDoc = doc;
      });
  
      this.pageData.saved = false;
  
      if (this.pageData.activity.requires_validation === 1) {
        this.showAttendanceCode = true;
      }  
    }

  /** 
  * Toggle attencance code.
  */
  toggleAttendanceCode() {
    this.showAttendanceCode = !this.showAttendanceCode;
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'activity-complete', data: this.navParams.data });
  }

  async ionViewCanLeave() {
    if (!this.pageData.saved) {
      const shouldLeave = await this.confirmLeave();
      return shouldLeave;
    }
  }

  /** 
  * Confirm leave.
  */
  confirmLeave(): Promise<Boolean> {
    let resolveLeaving;
    const canLeave = new Promise<Boolean>(resolve => resolveLeaving = resolve);
    const alert = MyUtil.presentAlert({
      title: 'Confirm Leave',
      message: "Have you completed this activity? If so, please 'go back' and press the complete button. Or choose 'not complete' to return to the main activity screen.",
      buttons: [
        {
          text: 'Go back',
          role: 'cancel',
          handler: () => resolveLeaving(false)
        },
        {
          text: 'Not complete',
          handler: () => resolveLeaving(true)
        }
      ]
    });
    // alert.present();
    return canLeave
  }

  rangeChange(hours){
    this.showHoursError = hours === 0;
  }
  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
 ratingError = '';
 recommendError = '';
 attendanceCodeError = '';
  process(action: string) {
    this.ratingError = '';
    this.recommendError = '';
    this.attendanceCodeError = '';
    switch (action) {
      case 'activity-complete':
        console.log('this.hours', this.hours)
        if (this.pageData.activity.activity_template_id) {
          this.setActivityComplete();
        } else {
          if (!this.rating) {
            this.ratingError = 'Please rate this activity using 1-5 stars before pressing complete.';
            // MyUtil.presentToast('Please rate this activity using 1-5 stars before pressing complete.', {
            //   duration: MyUtil.CONST.DURATION_TOAST_LONG,
            //   cssClass: 'inkpath-toast'
            // });
          } else if (!this.hide_hours && this.hours == 0) {
            this.showHoursError = true;
          } else if (this.recommend == null) {
            this.recommendError = 'Please indicate if you would recommend this activity yes/no before pressing complete.';

            // MyUtil.presentToast('Please indicate if you would recommend this activity yes/no before pressing complete.', {
            //   duration: MyUtil.CONST.DURATION_TOAST_LONG,
            //   cssClass: 'inkpath-toast'
            // });
          } else if (
            (!this.pageData.activity.profile_id)
            &&
            (this.pageData.activity.requires_validation == 1 && (!this.attendanceCode || this.attendanceCode.length < 10))
          ) {
            this.attendanceCodeError = 'Please enter a valid attendance code to complete this activity.';
            // MyUtil.presentToast('Please enter a valid attendance code to complete this activity.', {
            //   duration: MyUtil.CONST.DURATION_TOAST_LONG,
            //   cssClass: 'inkpath-toast'
            // });
          } else {
            const alert = MyUtil.presentAlert({
              title: 'Confirm Complete',
              message: "Once you mark this activity complete it cannot be removed. You can still add notes and attach files, but cannot change your feedback or delete your attendance.",
              buttons: [
                {
                  text: 'Go back',
                  role: 'cancel',
                },
                {
                  text: 'Complete',
                  handler: () => {
                    this.setActivityComplete();
                  }
                },
              ]
            });
          }
        }
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled');
        return;
    }
  }

  /** 
  * Set activty complete.
  */
  private setActivityComplete() {
    if (!this.pageData.userActivityDoc || this.pageData.userActivityDoc.delete) {
      MyUtil.presentToast('Activity is not in personal list', { cssClass: 'inkpath-toast' });
      return;
    }

    let time_logged = this.hours * 0.5 * 60;
    let rating = this.rating;
    let feedback = this.feedback;
    let recommend = this.recommend;
    let attendanceCode = this.attendanceCode;
    let reflection = this.reflection;

    //If the slider is hidden, just set the time logged to one hour
    if (this.hide_hours) {
      time_logged = 1 * 60;
    }

    // update data
    this.pageData.userActivityDoc.data.status = MyUtil.CONST.APP_META.USER_ACTIVITY_STATUS_DONE;
    this.pageData.userActivityDoc.data.time_logged = time_logged;
    this.pageData.userActivityDoc.data.rating = (rating == 0) ? null : rating;
    this.pageData.userActivityDoc.data.feedback = feedback;
    this.pageData.userActivityDoc.data.liked = recommend;
    this.pageData.userActivityDoc.data.completed_at = MyUtil.getUnixTimeStampWithoutAdjustment();
    this.pageData.userActivityDoc.data.attend_at = MyUtil.getUnixTimeStampWithoutAdjustment();
    this.pageData.userActivityDoc.data.attendance_code = attendanceCode;
    this.pageData.userActivityDoc.data.reflection = reflection;

    // remove ts if any for sync again
    delete (this.pageData.userActivityDoc.ts);

    MyDb.userSave(this.pageData.userActivityDoc).then((doc) => {
      this.pageData.userActivityDoc = doc;
      MyUtil.presentToast('Activity set as complete. You may add your file.', {
        duration: MyUtil.CONST.DURATION_TOAST_LONG,
        cssClass: 'inkpath-toast'
      });

      // attempt server update
      this.appapi.saveUserActivities().then(() => {
        window.history.back();
      })

      // mark saved
      this.pageData.saved = true;
    }).catch(() => {
      this.pageData.userActivityDoc = null;
    });
  }

  /** 
  * Update rating.
  * @param rating 
  */
  updateRating(rating) {
    this.rating = rating;
  }

  /** 
  * Validate code.
  */
  validateCode($event) {
    // avoid double enter event
    if (this.attendanceCode == this.pageData.previousAttendanceCode) {
      return;
    }
    this.pageData.previousAttendanceCode = this.attendanceCode;

    this.pageData.verifiedAttendanceCode = false;
    if (this.attendanceCode && this.attendanceCode.length >= 10) {
      this.validateAttendanceCode();
    }
  }

  /** 
  * Scan QR.
  */
  // scanQr() {
  //   let options: BarcodeScannerOptions;
  //   MyUtil.scanQr(options).then((result: BarcodeScanResult) => {
  //     MyUtil.debug(result);
  //     this.attendanceCode = result.text;
  //     this.validateAttendanceCode();
  //   }).catch(error => {
  //     MyUtil.error(error);
  //   });
  // }

  /** 
  * Validate attendance code.
  */
  private validateAttendanceCode() {
    // avoid invalid user activity doc
    if (!this.pageData.userActivityDoc || this.pageData.userActivityDoc.delete) {
      MyUtil.presentToast('Activity is not in personal list', { cssClass: 'inkpath-toast' });
      return;
    }

    this.pageData.verifiedAttendanceCode = false;

    let loading = MyUtil.presentLoading();
    let data = {
      activityId: this.pageData.userActivityDoc.data.activity_id,
      attendanceCode: this.attendanceCode
    };
    this.appapi.post('/verify/attendance-code', data).then(async (result) => {
      (await loading).dismiss();
      if (result['#status'] === 'success') {
        MyUtil.presentToast(result['#message']);
        this.pageData.verifiedAttendanceCode = true;
      } else {
        MyUtil.presentToast(result['#message'], { cssClass: 'inkpath-toast' });
      }
    }).catch(async err => {
      (await loading).dismiss();
      MyUtil.error(err);
      // Do nothing for the error request, esp. for the offline mode
      // leave the code to be verified when sync back to the server
      // allow retry with same code to verify.
      this.pageData.previousAttendanceCode = null;
    });
  }

  thumbsUp = false;
  thumbsDown = false;
  private recommendClick(mode) {

    // If they clicked the thumb up icon
    if (mode == 'thumb-up') {
      this.recommend = 1;
      this.thumbsUp = true;
      this.thumbsDown = false;
    }

    // If they clicked the thumb down button
    if (mode == 'thumb-down') {
      this.recommend = 0;
      this.thumbsUp = false;
      this.thumbsDown = true;
    }
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
